import { Colors } from 'core/CssVariables';
import {
  CompanyActionEnum,
  CompanyActionEnumUi,
  InteractionTypeEnum,
  InteractionTypeEnumUi,
  TAccountManagers,
  TAccountManagersUI,
  TComapnyGetData,
  TComapnyGetDataUi,
  TComapnyPostData,
  TComapnyPostDataUi,
  TCompanyAction,
  TCompanyActionUi,
  TCompanyStatus,
  TCompanyStatusMapperColor,
  TCompanyStatusUi,
  TEditCompanyAccountManager,
  TEditCompanyAddress,
  TEditCompanyAddressUi,
  TEditCompanyDomain,
  TEditCompanyEmployee,
  TEditCompanyEmployeeUi,
  TEditCompanyLogo,
  TEditCompanyMainContact,
  TEditCompanyName,
  TEditCompanyPhone,
  TEditCompanySegment,
  TEditCompanyShortName,
  TEditCompanyUi,
  TEditCompanyWebsite,
  TIteractionAssignee,
  TIteractionAssigneeUi,
  TIteractionReporter,
  TIteractionReporterUi,
  TInteractionGet,
  TInteractionGetUi,
  TInteractionPost,
  TInteractionPostUi,
  TMarkAsContacted,
  TMarkAsContactedUi,
  TMarkAsCustomer,
  TMarkAsCustomerUi,
  TMarkAsFormerCustomer,
  TMarkAsFormerCustomerUi,
  TMarkAsLead,
  TMarkAsLeadUi,
  TMarkAsNotAFit,
  TMarkAsNotAFitUi,
  TReScheduleInteractionPost,
  TReScheduleInteractionPostUi,
  TScheduleInteractionGet,
  TScheduleInteractionGetUi,
  TScheduleInteractionPost,
  TScheduleInteractionPostUi,
  TResolveInteractionUi,
  TResolveInteraction,
  InteractionTypeAsParam,
  TCompanyCsvPreviewUi,
  TCompanyCsvPreview,
  TEditInteractionDescriptionUi,
  TEditInteraction,
  TEditInteractionTypeUi,
  TEditInteractionType,
  TInteractionTypeMapperColor,
  TEditScheduleInteractionDescUi,
  TEditScheduleInteractionDesc,
  TCsvPreviewFailedCompanyUi,
  TCsvPreviewFailedCompany,
  TBulkLogInteractionPostUi,
  TBulkLogInteractionPost,
  TBulkLogInteractionGetUi,
  TBulkLogInteractionGet,
  TCompanyStatusesBasedOnTabs,
  AllCompaniesPath,
  TCompanyStatusAsParam,
  TEditCompanyInvoiceTermsUi,
  TEditCompanyInvoiceTerms,
  TCompanyDomainGetUi,
  TCompanyDomainGet,
  TEditCompanyInvoiceRecipientEmailsUi,
  TEditCompanyInvoiceRecipientEmails,
  TEditCompanyEndpointType,
  TEditCompanyEndpointTypeUi,
  ECompanyEndpointTypeUi,
  ECompanyEndpointType,
  TBlockHoursGetUi,
  TBlockHoursGet,
  TBlockHoursPostUi,
  TBlockHoursPost,
  TBlockHoursGetRemainingHoursResponseUi,
  TBlockHoursGetRemainingHoursResponse,
  TBlockHoursGetRemainingHoursParamsUi,
  TBlockHoursGetRemainingHoursParams,
  TComapnyGetDataForOptions,
  TComapnyGetDataForOptionsUi,
} from 'data/types/company.types';
import {
  TConverter,
  TAuthConverter,
  TCreateConverter,
  TFetchConverter,
} from 'data/types/converter.types';
import { getDateTimeFormats } from 'helpers/utils';
import moment from 'moment';
import { GetFirstLetters } from './general.converters';
import { contactShortDataConverter } from './shortDataConverter';

export const BlockHoursConverter: TAuthConverter<
  TBlockHoursPostUi,
  TBlockHoursPost,
  TBlockHoursGetUi,
  TBlockHoursGet
> = {
  fromDb: data => {
    const { dateFormat } = getDateTimeFormats();

    if (data.blockHoursEnabled) {
      return {
        blockHoursEnabled: data.blockHoursEnabled,
        blockHoursData: {
          remainingHours: data.remainingHours || 0,
          startingHours: data.startingHours || 0,
          blockHoursStart: data.blockHoursStart
            ? moment(data.blockHoursStart).format(dateFormat)
            : '',
          blockHoursEnd:
            data.blockHoursEnd && moment(data.blockHoursEnd).format(dateFormat),
        },
      };
    }
    return {
      blockHoursEnabled: data.blockHoursEnabled,
    };
  },
  toDb: data => {
    return {
      blockHoursEnabled: data.blockHoursEnabled,
      startingHours: data.startingHours,
      blockHoursStart: moment(data.blockHoursStart).format('YYYY-MM-DD'),
      blockHoursEnd: data.blockHoursEnd
        ? moment(data.blockHoursEnd).format('YYYY-MM-DD')
        : undefined,
    };
  },
};
export const accountManagerConverter: TConverter<
  TAccountManagersUI,
  TAccountManagers
> = {
  toDb: data => {
    return {
      id: data.id,
      name: data.name,
      photoUrl: data.photoUrl,
      email: data.email ? data.email : '',
      phoneNumber: data.phoneNumber || '',
    };
  },
  fromDb: data => {
    return {
      id: data.id,
      name: data.name,
      photoUrl: data.photoUrl,
      email: data.email ? data.email : '',
      phoneNumber: data.phoneNumber || '',
    };
  },
};

export const companyStatusConverter: TConverter<
  TCompanyStatusUi,
  TCompanyStatus
> = {
  fromDb: data => {
    return TCompanyStatusUi[
      TCompanyStatus[data] as keyof typeof TCompanyStatusUi
    ];
  },
  toDb: data => {
    return TCompanyStatus[
      TCompanyStatusUi[data] as keyof typeof TCompanyStatus
    ];
  },
};

export const companyEndpointTypeConverter: TConverter<
  ECompanyEndpointTypeUi,
  ECompanyEndpointType
> = {
  fromDb: data => {
    const convertedDataMapper: {
      [key in ECompanyEndpointType]: ECompanyEndpointTypeUi;
    } = {
      [ECompanyEndpointType.CONTACTS]: ECompanyEndpointTypeUi.CONTACTS,
      [ECompanyEndpointType.DEVICES]: ECompanyEndpointTypeUi.DEVICES,
      [ECompanyEndpointType.EMPLOYEES]: ECompanyEndpointTypeUi.EMPLOYEES,
    };
    return convertedDataMapper[data];
  },
  toDb: data => {
    const convertedDataMapper: {
      [key in ECompanyEndpointTypeUi]: ECompanyEndpointType;
    } = {
      [ECompanyEndpointTypeUi.CONTACTS]: ECompanyEndpointType.CONTACTS,
      [ECompanyEndpointTypeUi.DEVICES]: ECompanyEndpointType.DEVICES,
      [ECompanyEndpointTypeUi.EMPLOYEES]: ECompanyEndpointType.EMPLOYEES,
    };
    return convertedDataMapper[data];
  },
};

const companyActionConverter: TFetchConverter<
  TCompanyActionUi,
  TCompanyAction
> = {
  fromDb: data => {
    return {
      action:
        CompanyActionEnumUi[
          CompanyActionEnum[data.action] as keyof typeof CompanyActionEnumUi
        ],
      nextPossibleStatuses: data.nextPossibleStatuses.map(item =>
        companyStatusConverter.fromDb(item),
      ),
    };
  },
};

export const CompanyStatusMapperColor: TCompanyStatusMapperColor = {
  [TCompanyStatus['Never Contacted']]: Colors.Orange,
  [TCompanyStatus.Marketing]: Colors.PrimaryColor,
  [TCompanyStatus.Lead]: Colors.InfoColor,
  [TCompanyStatus['Not A Fit']]: Colors.WarningColor,
  [TCompanyStatus.Customer]: Colors.SuccessColor,
  [TCompanyStatus['Former Customer']]: Colors.LightGrey,
};
export const interactionTypeConverter: TConverter<
  InteractionTypeEnumUi,
  InteractionTypeEnum
> = {
  fromDb: data => {
    return InteractionTypeEnumUi[
      InteractionTypeEnum[data] as keyof typeof InteractionTypeEnumUi
    ];
  },
  toDb: data => {
    return InteractionTypeEnum[
      InteractionTypeEnumUi[data] as keyof typeof InteractionTypeEnum
    ];
  },
};
export const iteractionReporterConverter: TFetchConverter<
  TIteractionReporterUi,
  TIteractionReporter
> = {
  fromDb: data => {
    return {
      id: data.id,
      name: data.name,
      photoUrl: data.photoUrl,
      firstLetters: GetFirstLetters.fromDb(data.name),
    };
  },
};
export const iteractionAssigneeConverter: TFetchConverter<
  TIteractionAssigneeUi,
  TIteractionAssignee
> = {
  fromDb: data => {
    return {
      id: data.id,
      name: data.name,
      photoUrl: data.photoUrl,
      firstLetters: GetFirstLetters.fromDb(data.name),
    };
  },
};
export const shceduleInteractionConverter: TAuthConverter<
  TScheduleInteractionPostUi,
  TScheduleInteractionPost,
  TScheduleInteractionGetUi,
  TScheduleInteractionGet
> = {
  toDb: data => {
    return {
      type: interactionTypeConverter.toDb(data.type),
      description: data.description,
      scheduleTime: data.scheduleTime,
      assigneeId: data.assigneeId,
    };
  },
  fromDb: data => {
    const { dateFormat } = getDateTimeFormats();
    return {
      id: data.id,
      type: interactionTypeConverter.fromDb(data.type),
      description: data.description,
      scheduleTime: moment(data.scheduleTime).format(dateFormat),
      assignee: iteractionAssigneeConverter.fromDb(data.assignee),
      reporter: iteractionReporterConverter.fromDb(data.reporter),
      overdueSince: data.overdueSince,
      overdueSeverity: data.overdueSeverity,
    };
  },
};
export const reShceduleInteractionConverter: TCreateConverter<
  TReScheduleInteractionPostUi,
  TReScheduleInteractionPost
> = {
  toDb: data => {
    return {
      type: interactionTypeConverter.toDb(data.type),
      description: data.description,
      scheduleTime: data.scheduleTime,
      assigneeId: data.assigneeId,
    };
  },
};
export const resolveInteractionConverter: TCreateConverter<
  TResolveInteractionUi,
  TResolveInteraction
> = {
  toDb: data => {
    return {
      type: interactionTypeConverter.toDb(data.type),
      description: data.description,
      interactionDateTime: data.interactionDateTime,
    };
  },
};
export const interactionConverter: TAuthConverter<
  TInteractionPostUi,
  TInteractionPost,
  TInteractionGetUi,
  TInteractionGet
> = {
  toDb: data => ({
    companyId: data.companyId,
    type: interactionTypeConverter.toDb(data.type),
    description: data.description,
    interactionDateTime: data.interactionDateTime,
  }),
  fromDb: data => ({
    id: data.id,
    interactionType: interactionTypeConverter.fromDb(data.interactionType),
    description: data.description,
    interactionDateTime: moment(data.interactionDateTime).format(
      getDateTimeFormats().dateTimeFormat,
    ),
    reporter:
      data.reporter && iteractionReporterConverter.fromDb(data.reporter),
    companyId: data.companyId,
  }),
};
export const bulkLogInteractionConverter: TAuthConverter<
  TBulkLogInteractionPostUi,
  TBulkLogInteractionPost,
  TBulkLogInteractionGetUi,
  TBulkLogInteractionGet
> = {
  toDb: data => ({
    companyIds: data.companyIds,
    type: interactionTypeConverter.toDb(data.type),
    description: data.description,
    interactionDateTime: data.interactionDateTime,
  }),
  fromDb: data => ({
    companyInteractions: data.companyInteractions.length
      ? data.companyInteractions.map(interaction =>
          interactionConverter.fromDb(interaction),
        )
      : [],
    failedCompanyIds: data.failedCompanyIds,
  }),
};
export const companyDomainNamesConverter: TFetchConverter<
  TCompanyDomainGetUi,
  TCompanyDomainGet
> = {
  fromDb: data => {
    return {
      id: data.id,
      domainName: data.domainName,
    };
  },
};

export const companyConverter: TAuthConverter<
  TComapnyPostDataUi,
  TComapnyPostData,
  TComapnyGetDataUi,
  TComapnyGetData
> = {
  toDb: (data: TComapnyPostDataUi): TComapnyPostData => {
    return {
      name: data.name,
      accountManagerId: data.accountManagerId,
      marketSegment: data.marketSegment,
      domainNames: data.domainNames,
      logoUrl: data.logoUrl,
      website: data.website,
      numberOfEmployees: data.numberOfEmployees,
      relationshipStatus: companyStatusConverter.toDb(data.relationshipStatus),
      ...(data.shortName && { shortName: data.shortName }),
      ...(data.phoneNumber && {
        phoneNumber: data.phoneNumber,
      }),
      ...(data.address && { address: data.address }),
      invoiceTerms: data.invoiceTerms,
    };
  },
  fromDb: (data: TComapnyGetData): TComapnyGetDataUi => {
    const arrActions = Object.keys(CompanyActionEnumUi);
    return {
      id: data.id,
      name: data.name,
      logoUrl: data.logoUrl,
      address: data.address,
      accountManager:
        data.accountManager &&
        accountManagerConverter.fromDb(data.accountManager),
      website: data.website,
      marketSegment: data.marketSegment,
      phoneNumber: data.phoneNumber || '',
      domainNames:
        data.domainNames &&
        data.domainNames.map(domain =>
          companyDomainNamesConverter.fromDb(domain),
        ),
      mainContact:
        data.mainContact && contactShortDataConverter.fromDb(data.mainContact),
      firstLetters: GetFirstLetters.fromDb(data.name),
      shortName: data.shortName,
      nameWithAbbreviation: data.shortName
        ? `${data.shortName} - ${data.name}`
        : data.name,
      relationshipStatus: companyStatusConverter.fromDb(
        data.relationshipStatus,
      ),
      numberOfEmployees: data.numberOfEmployees,
      actions:
        data.actions
          ?.sort((a, b) => {
            return arrActions.indexOf(a.action) - arrActions.indexOf(b.action);
          })
          .map(action => companyActionConverter.fromDb(action)) || [],
      scheduledInteraction:
        data.scheduledInteraction &&
        shceduleInteractionConverter.fromDb(data.scheduledInteraction),
      specialNotes: data.specialNotes,
      invoiceTerms: data.invoiceTerms,
      invoiceRecipientEmails: data.invoiceRecipientEmails,
      isMsp: data.isMsp,
      endpointType:
        data.endpointType &&
        companyEndpointTypeConverter.fromDb(data.endpointType),
      contactCount: String(data.contactCount),
      deviceCount: String(data.deviceCount),
      ...(data.blockHours && {
        blockHours: BlockHoursConverter.fromDb(data.blockHours),
      }),
      notes: data.notes
    };
  },
};

export const companyForOptionsConverter: TFetchConverter<
TComapnyGetDataForOptionsUi,
TComapnyGetDataForOptions
> = {
  fromDb: (data: TComapnyGetDataForOptions) => {
    return {
      id: data.id,
      name: data.name,
      logoUrl: data.logoUrl,
      nameWithAbbreviation: data.shortName
        ? `${data.shortName} - ${data.name}`
        : data.name,
      address: data.address,
      phoneNumber: data.phoneNumber,
      invoiceRecipientEmails: data.invoiceRecipientEmails,
      invoiceTerms: data.invoiceTerms,
      shortName: data.shortName
    }
  }
};

// edit converters  strat
export const companyShortNameEditConverter: TCreateConverter<
  TEditCompanyUi,
  TEditCompanyShortName
> = {
  toDb: data => ({
    id: data.id,
    value: data.value === "" ? undefined : data.value,
  }),
};
export const companyMainContactEditConverter: TCreateConverter<
  TEditCompanyUi,
  TEditCompanyMainContact
> = {
  toDb: data => ({
    id: data.id,
    value: data.value,
  }),
};
export const companyLogoEditConverter: TCreateConverter<
  TEditCompanyUi,
  TEditCompanyLogo
> = {
  toDb: data => ({
    id: data.id,
    value: data.value,
  }),
};
export const companySegmentEditConverter: TCreateConverter<
  TEditCompanyUi,
  TEditCompanySegment
> = {
  toDb: data => ({
    id: data.id,
    value: data.value,
  }),
};
export const companyWebsiteEditConverter: TCreateConverter<
  TEditCompanyUi,
  TEditCompanyWebsite
> = {
  toDb: data => ({
    id: data.id,
    value: data.value,
  }),
};
export const companyDomainEditConverter: TCreateConverter<
  TEditCompanyUi,
  TEditCompanyDomain
> = {
  toDb: data => ({
    id: data.id,
    value: data.value,
  }),
};
export const companyAccountManagerEditConverter: TCreateConverter<
  TEditCompanyUi,
  TEditCompanyAccountManager
> = {
  toDb: data => ({
    id: data.id,
    value: data.value,
  }),
};
export const companyNameEditConverter: TCreateConverter<
  TEditCompanyUi,
  TEditCompanyName
> = {
  toDb: data => ({
    id: data.id,
    value: data.value,
  }),
};
export const companyPhoneEditConverter: TCreateConverter<
  TEditCompanyUi,
  TEditCompanyPhone
> = {
  toDb: data => ({
    id: data.id,
    value: data.value,
  }),
};
export const companyAddressEditConverter: TCreateConverter<
  TEditCompanyAddressUi,
  TEditCompanyAddress
> = {
  toDb: data => ({
    id: data.id,
    value: data.address,
  }),
};
export const companyEmployeeEditConverter: TCreateConverter<
  TEditCompanyEmployeeUi,
  TEditCompanyEmployee
> = {
  toDb: data => ({
    id: data.id,
    value: Number(data.value),
  }),
};
export const companyInvoiceTermsEditConverter: TCreateConverter<
  TEditCompanyInvoiceTermsUi,
  TEditCompanyInvoiceTerms
> = {
  toDb: data => ({
    id: data.id,
    value: data.value,
  }),
};
export const companyInvoiceRecipientEmailsEditConverter: TCreateConverter<
  TEditCompanyInvoiceRecipientEmailsUi,
  TEditCompanyInvoiceRecipientEmails
> = {
  toDb: data => ({
    id: data.id,
    value: data.value,
  }),
};
export const interactionDescriptionEditConverter: TCreateConverter<
  TEditInteractionDescriptionUi,
  TEditInteraction
> = {
  toDb: data => ({
    interactionId: data.interactionId,
    value: data.value,
  }),
};
export const interactionTypeEditConverter: TCreateConverter<
  TEditInteractionTypeUi,
  TEditInteractionType
> = {
  toDb: data => ({
    interactionId: data.interactionId,
    value: interactionTypeConverter.toDb(data.value),
  }),
};
export const scheduleInteractionDescEditConverter: TCreateConverter<
  TEditScheduleInteractionDescUi,
  TEditScheduleInteractionDesc
> = {
  toDb: data => ({
    companyId: data.companyId,
    value: data.value,
  }),
};
export const companyEndpointTypeEditConverter: TCreateConverter<
  TEditCompanyEndpointTypeUi,
  TEditCompanyEndpointType
> = {
  toDb: data => ({
    companyId: data.companyId,
    value: companyEndpointTypeConverter.toDb(data.value),
  }),
};
// edit converters  end

// action buttons converters start
export const markAsContactedConverter: TCreateConverter<
  TMarkAsContactedUi,
  TMarkAsContacted
> = {
  toDb: data => {
    return {
      nextStatus: companyStatusConverter.toDb(data.nextStatus),
      description: data.description,
    };
  },
};
export const markAsLeadConverter: TCreateConverter<TMarkAsLeadUi, TMarkAsLead> =
  {
    toDb: data => {
      return {
        nextStatus: companyStatusConverter.toDb(data.nextStatus),
        description: data.description,
      };
    },
  };
export const markAsnotAFitConverter: TCreateConverter<
  TMarkAsNotAFitUi,
  TMarkAsNotAFit
> = {
  toDb: data => {
    return {
      nextStatus: companyStatusConverter.toDb(data.nextStatus),
      description: data.description,
    };
  },
};
export const markAsCustomerConverter: TCreateConverter<
  TMarkAsCustomerUi,
  TMarkAsCustomer
> = {
  toDb: data => {
    return {
      nextStatus: companyStatusConverter.toDb(data.nextStatus),
      description: data.description,
    };
  },
};
export const markAsFormerCustomerConverter: TCreateConverter<
  TMarkAsFormerCustomerUi,
  TMarkAsFormerCustomer
> = {
  toDb: data => {
    return {
      nextStatus: companyStatusConverter.toDb(data.nextStatus),
      description: data.description,
    };
  },
};

export const interactionTypeAsParamConverter: TCreateConverter<
  InteractionTypeEnumUi,
  InteractionTypeAsParam
> = {
  toDb: data => {
    return InteractionTypeAsParam[
      InteractionTypeEnumUi[data] as keyof typeof InteractionTypeAsParam
    ];
  },
};

export const csvPreviewfailedCompanyConverter: TFetchConverter<
  TCsvPreviewFailedCompanyUi,
  TCsvPreviewFailedCompany
> = {
  fromDb: data => {
    return {
      companyName: data.companyName,
      failReason: data.failReason,
    };
  },
};

export const companyCsvPreviewConverter: TFetchConverter<
  TCompanyCsvPreviewUi,
  TCompanyCsvPreview
> = {
  fromDb: data => {
    return {
      passedCompanies: data.passedCompanies.length
        ? data.passedCompanies.map(company => companyConverter.fromDb(company))
        : [],
      failedCompanies: data.failedCompanies.length
        ? data.failedCompanies.map(failedcompany =>
            csvPreviewfailedCompanyConverter.fromDb(failedcompany),
          )
        : [],
    };
  },
};
export const InteractionTypeMapperColor: TInteractionTypeMapperColor = {
  [InteractionTypeEnumUi['vCIO Meeting']]: Colors.WarningColor,
  [InteractionTypeEnumUi.Marketing]: Colors.SecondaryColor,
  [InteractionTypeEnumUi['Training Meeting']]: Colors.InfoColor,
  [InteractionTypeEnumUi['Pop-in']]: Colors.SuccessColor,
  [InteractionTypeEnumUi.Sales]: Colors.Orange,
};

export const CompanyStatusesBasedOnTabs: TCompanyStatusesBasedOnTabs = {
  [AllCompaniesPath.CUSTOMERS]: [TCompanyStatusAsParam.CUSTOMER],
  [AllCompaniesPath.FORMER_CUSTOMERS]: [TCompanyStatusAsParam.FORMER_CUSTOMER],
  [AllCompaniesPath.POTENTIAL_CUSTOMERS]: [
    TCompanyStatusAsParam.LEAD,
    TCompanyStatusAsParam.MARKETING,
    TCompanyStatusAsParam.NEVER_CONTACTED,
    TCompanyStatusAsParam.NOT_A_FIT,
  ],
};

export const BlockHoursRemainingHoursResponseConverter: TFetchConverter<
  TBlockHoursGetRemainingHoursResponseUi,
  TBlockHoursGetRemainingHoursResponse
> = {
  fromDb: data => {
    return {
      remainingHours: data.remainingHours,
    };
  },
};

export const BlockHoursRemainingHoursConverter: TCreateConverter<
  TBlockHoursGetRemainingHoursParamsUi,
  TBlockHoursGetRemainingHoursParams
> = {
  toDb: data => {
    return {
      startingHours: data.startingHours,
      blockHoursStart: moment(data.blockHoursStart).format('YYYY-MM-DD'),
      blockHoursEnd: data.blockHoursEnd
        ? moment(data.blockHoursEnd).format('YYYY-MM-DD')
        : undefined,
    };
  },
};
