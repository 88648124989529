import { TIconNames } from 'components/Icon';
import { TUserRoleUi } from 'data/types/user.types';
import { lazy } from 'react';
import { PrivateRouteType, RoutesPaths } from '../Routes.types';

const IssueTypes = lazy(
  () => import('pages/private/Configurations/Issues/Issues'),
);
const Queues = lazy(() => import('pages/private/Configurations/Queues/Queues'));
const Settings = lazy(() => import('pages/private/Settings/Settings'));
const CategorySettings = lazy(
  () =>
    import('pages/private/Configurations/CategorySettings/CategorySettings'),
);
const PSL = lazy(() => import('pages/private/Configurations/PSL/AllPSL'));
const QueueDetails = lazy(
  () => import('pages/private/Configurations/Queues/QueueDetails'),
);
const ContractTypes = lazy(
  () => import('pages/private/Configurations/ContractTypes/ContractTypes'),
);
const Notifications = lazy(
  () => import('pages/private/Configurations/Notifications/Notifications'),
);

export const ConfigurationSubRoutes: PrivateRouteType[] = [
  {
    title: 'Issue Types',
    component: IssueTypes,
    path: RoutesPaths.IssueTypes,
    index: true,
    icon: TIconNames.ISSUE_TYPE,
    roles: [TUserRoleUi.Admin, TUserRoleUi.Owner],
  },
  {
    title: 'Queues',
    component: Queues,
    path: RoutesPaths.Queues,
    icon: TIconNames.QUEUE,
    roles: [TUserRoleUi.Admin, TUserRoleUi.Owner],
  },
  {
    title: 'Services and Products',
    component: PSL,
    path: RoutesPaths.PSL,
    index: true,
    icon: TIconNames.PSL,
    roles: [TUserRoleUi.Admin, TUserRoleUi.Technician, TUserRoleUi.Owner],
  },
  {
    title: 'Settings',
    component: Settings,
    path: RoutesPaths.Settings,
    icon: TIconNames.TUNE,
    roles: [TUserRoleUi.Admin, TUserRoleUi.Technician, TUserRoleUi.Owner],
  },
  {
    title: 'Category Settings',
    component: CategorySettings,
    path: RoutesPaths.CategorySettings,
    icon: TIconNames.CATEGORY_SETTINGS,
    roles: [TUserRoleUi.Admin, TUserRoleUi.Owner],
  },
  {
    title: 'Contract Types',
    component: ContractTypes,
    roles: [TUserRoleUi.Admin, TUserRoleUi.Owner],
    path: RoutesPaths.ContractTypes,
    icon: TIconNames.CONTRACT_TYPE,
  },
  {
    title: 'Notifications',
    component: Notifications,
    roles: [TUserRoleUi.Admin, TUserRoleUi.Owner],
    path: RoutesPaths.Notifications,
    icon: TIconNames.UNREAD,
    featureFlag: 'notifications',
  },
  {
    component: QueueDetails,
    path: RoutesPaths.QueueDetails,
    notInSidebar: true,
    roles: [TUserRoleUi.Admin, TUserRoleUi.Owner],
  },
];
