import React, { useMemo } from 'react';
import Form, { FormInstance } from 'antd/es/form';
import { Col, Row } from 'components/Grid/Grid';
import { FormItem } from 'components/Form/FormItem';
import { Input, TextArea } from 'components/Input';
import Select from 'components/Select/Select';
import { TCreateTaskUi } from 'data/types/task.type';
import { useGetCompaniesCustomersForOptionsQuery } from 'redux/apiSlices/company.slice';
import { useGetTaskAssigneesQuery } from 'redux/apiSlices/task.slices';
import { TOptionType } from 'data/types/generalDataTypes';
import { regexPatterns } from 'components/Form/validationRules';
import { MemberSelectLabel } from 'components/MembersSelect';
import { FileUploader } from 'components/FileUploader/FileUploader';

type TProps = {
  form: FormInstance<TCreateTaskUi>;
};
const CreateTaskForm: React.FC<TProps> = ({ form }) => {
  const { data: assigneesList, isLoading: assigneesListLoading } =
    useGetTaskAssigneesQuery();

  const { data: companyList, isLoading: companyListLoading } =
    useGetCompaniesCustomersForOptionsQuery({
      sort: 'name,ASC',
      includePotential: true
    });

  const companiesOptions: TOptionType[] = useMemo(() => {
    if (companyList?.length) {
      return companyList?.map(({ id, name }) => ({
        value: id,
        label: name,
      }));
    }
    return [];
  }, [companyList]);

  return (
    <Form
      form={form}
      name="task"
      autoComplete="off"
      preserve={false}
      initialValues={{ attachments: [] }}
    >
      <Row gutter={[10, 0]}>
        <Col xs={24} className="mb-6">
          <FormItem
            name="title"
            label="Task title"
            rules={[
              {
                required: true,
                message: 'This field is required',
                pattern: regexPatterns.onlyWhiteSpace,
              },
            ]}
          >
            <Input placeholder="Type task title" />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={[10, 0]}>
        <Col xs={24} className="mb-6">
          <FormItem
            name="description"
            label="Description (optional)"
            initialValue=""
          >
            <TextArea className="customScroll" placeholder="Type here" />
          </FormItem>
        </Col>
        <Col xs={24} className="mb-2">
          <FormItem name="attachments">
            <FileUploader />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={[10, 0]}>
        <Col xs={24} md={12} className="mb-6">
          <FormItem
            name="companyId"
            label="Company"
            rules={[
              {
                required: true,
                message: 'This field is required',
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Select"
              options={companiesOptions}
              disabled={companyListLoading}
            />
          </FormItem>
        </Col>
        <Col xs={24} md={12} className="mb-6">
          <FormItem
            name="assigneeId"
            label="Primary resource"
            rules={[
              {
                required: true,
                message: 'This field is required',
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Select"
              value={form.getFieldValue('assigneeId')}
              options={assigneesList?.map(({ id, name, photoUrl }) => ({
                value: id,
                label: (
                  <MemberSelectLabel
                    name={name}
                    photoUrl={photoUrl}
                    key={name}
                  />
                ),
              }))}
              disabled={assigneesListLoading}
            />
          </FormItem>
        </Col>
      </Row>
    </Form>
  );
};

export { CreateTaskForm };
