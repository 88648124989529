import { FC } from 'react';
import { TIconNames } from 'components/Icon';
import { TUserRoleUi } from '../data/types/user.types';

export type PublicRouteType = {
  component: FC<any>;
  path: string;
  index?: boolean;
};

export type PrivateRouteType = PublicRouteType & {
  title?: string;
  icon?: TIconNames;
  subRoutes?: PrivateRouteType[];
  notInSidebar?: boolean;
  featureFlag?: string;
  roles: TUserRoleUi[];
};

export enum RoutesPaths {
  Login = 'login',
  SignUp = 'sign-up',
  EmailVerification = 'email-verification',
  ConfirmInvitation = 'confirm-invitation',
  ResetPassword = 'reset-password',
  UiKit = 'uikit',
  Workfeed = 'workfeed',
  ContractTypes = 'configurations/contract-types',
  Notifications = 'configurations/email-notification-configuration',
  Settings = 'configurations/settings',
  Tickets = 'tickets',
  TicketDetails = 'tickets/:id',
  Tasks = 'tasks',
  TaskDetails = 'tasks/:id',
  Customers = 'customers',
  Organizations = 'customers/companies',
  Contacts = 'customers/contacts',
  ItGoals = 'customers/it-goals',
  Devices = 'customers/devices',
  ContactDetails = 'customers/contacts/:id',
  ContractDetails = 'customers/companies/:id/contract/:id',
  ContractCreate = 'customers/companies/:id/create-contract',
  OrganizationDetails = 'customers/companies/:id',
  Timesheet = 'timesheet',
  Projects = 'projects',
  ProjectDetails = 'projects/:id',
  PhaseTaskDetails = 'projects/tasks/:id',
  Reports = 'reports',
  ReportsServiceQueue = 'reports/service-queue',
  ReportsTech = 'reports/tech',
  ReportsCustomer = 'reports/customer',
  ReportsMsp = 'reports/msp',
  TeamManagement = 'team-management',
  Invitations = 'team-management/invitations',
  Members = 'team-management/members',
  MemberDetails = 'team-management/members/:id',
  UserGroups = 'team-management/user-groups',
  Configurations = 'configurations',
  IssueTypes = 'configurations/issue-types',
  CategorySettings = 'configurations/category-settings',
  QueueDetails = 'configurations/queues/:id',
  Queues = 'configurations/queues',
  TIME_ENTRIES = 'time-entries',
  Profile = 'my-profile',
  PageNotFound = '404',
  PSL = 'configurations/psl',
  Invoices = 'invoices',
  Integrations = 'integrations',
  Payment = 'payment',
  AccessLimitedTechnician = 'access-limited',
  IntegrationDetails = 'integrations/rmm/:type',
  DeviceDetails = 'customers/devices/:id',
  OutlookConnection = 'integrations/outlook-confirm',
}
