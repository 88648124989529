import { rest } from 'msw';
import { DEVICES_URL } from 'redux/apiSlices/devices.slice';
import {
  TbulkAttachDevicesPost,
  TDevicePostUi,
  TUpdateDeviceModelUi,
  TUpdateDeviceNameUi,
} from 'data/types/devices.type';
import { companyAMockedShortData } from '../data/company.data';
import { device1, mockedDevicesData, mockedPagableDevicesData } from '../data/devices.data';

export const devicesHandler = [
  rest.get(`${DEVICES_URL}`, (req, res, ctx) => {
    const companiesParam = req.url.searchParams.get('companyIds');
    if (companiesParam === companyAMockedShortData.id) {
      return res(ctx.status(200), ctx.json(mockedPagableDevicesData));
    }
    if (companiesParam === 'fakeCompanyId') {
      return res(ctx.status(500), ctx.json({ message: 'fake company' }));
    }
    return res(ctx.status(200), ctx.json(mockedPagableDevicesData));
  }),
  rest.get(`${DEVICES_URL}/compact`, (req, res, ctx) => {
    const companiesParam = req.url.searchParams.get('companyIds');
    if (companiesParam === companyAMockedShortData.id) {
      return res(ctx.status(200), ctx.json(mockedDevicesData));
    }
    if (companiesParam === 'fakeCompanyId') {
      return res(ctx.status(500), ctx.json({ message: 'fake company' }));
    }
    return res(ctx.status(200), ctx.json(mockedDevicesData));
  }),
  rest.patch(`${DEVICES_URL}/:id/updateName`, (req, res, ctx) => {
    const requestBody: TUpdateDeviceNameUi = req.body as TUpdateDeviceNameUi;
    if (requestBody.value === 'newValidName') {
      return res(ctx.status(200));
    }
    return res(ctx.status(500), ctx.json({ message: 'fake name error' }));
  }),
  rest.patch(`${DEVICES_URL}/:id/updateDeviceType`, (req, res, ctx) => {
    const requestBody: TUpdateDeviceNameUi = req.body as TUpdateDeviceNameUi;
    if (requestBody.value === 'newValidType') {
      return res(ctx.status(200));
    }
    return res(ctx.status(500), ctx.json({ message: 'fake type error' }));
  }),
  rest.put(`${DEVICES_URL}/:id/attach-to-contact`, (req, res, ctx) => {
    return res(ctx.status(200));
  }),
  rest.put(`${DEVICES_URL}/:id/detach-from-contact`, (req, res, ctx) => {
    return res(ctx.status(200));
  }),
  rest.patch(`${DEVICES_URL}/:id/updateModel`, (req, res, ctx) => {
    const requestBody: TUpdateDeviceModelUi = req.body as TUpdateDeviceModelUi;
    if (requestBody.value === 'newValidModel') {
      return res(ctx.status(200));
    }
    return res(ctx.status(500), ctx.json({ message: 'fake model error' }));
  }),
  rest.patch(`${DEVICES_URL}/:id/active`, (req, res, ctx) => {
    return res(ctx.status(200));
  }),
  rest.patch(`${DEVICES_URL}/:id/managed`, (req, res, ctx) => {
    return res(ctx.status(200));
  }),
  rest.get(`${DEVICES_URL}/:id`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(device1));
  }),
  rest.delete(`${DEVICES_URL}/:id`, (req, res, ctx) => {
    return res(ctx.status(200));
  }),
  rest.put(`${DEVICES_URL}/bulk-attach-to-contact`, (req, res, ctx) => {
    const body: TbulkAttachDevicesPost = req.body as TbulkAttachDevicesPost;
    if (
      !body.contactDevices[0].devices.length &&
      body.contactDevices[1].devices.length === 2
    ) {
      return res(ctx.status(200));
    }
    return res(ctx.status(500), ctx.json({ message: 'error to bulk attach' }));
  }),
  rest.post(`${DEVICES_URL}`, async (req, res, ctx) => {
    const { companyId } = (await req.json()) as TDevicePostUi;
    if (companyId === 'companyId12345') {
      return res(
        ctx.status(500),
        ctx.json({ message: 'fail to create device' }),
      );
    }
    return res(ctx.status(200));
  }),
];
