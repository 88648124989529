import React, { useMemo } from 'react';
import Form, { FormInstance } from 'antd/es/form';
import { Col, Row } from 'components/Grid/Grid';
import { FormItem } from 'components/Form/FormItem';
import { Input, TextArea } from 'components/Input';
import Select from 'components/Select/Select';
import { TCreateTicketUi } from 'data/types/ticket.types';
import { useGetQueuesQuery } from 'redux/apiSlices/queues.slice';
import { useGetIssuesQuery } from 'redux/apiSlices';
import { useGetCompaniesCustomersForOptionsQuery } from 'redux/apiSlices/company.slice';
import {
  useGetAllAssignedMembersForFilterQuery,
  useGetAllTicketPriorityQuery,
} from 'redux/apiSlices/ticket.slice';
import { MemberSelectLabel } from 'components/MembersSelect';
import { FileUploader } from 'components/FileUploader/FileUploader';
import { useGenerateTitleMutation } from 'redux/apiSlices/ai.slices';
import { toast } from 'components/Notification';
import { TButtonTypes } from 'components/Button/Button.types';
import Button from 'components/Button/Button';
import { Icon, TIconNames } from 'components/Icon';
import useCompanyContactOptionsCompact from 'hooks/useCompanyContactOptionsCompact';
import { useDeviceGroupedOptions } from 'hooks/useDeviceGroupedOptions';

type TProps = {
  form: FormInstance<TCreateTicketUi>;
};
const CreateTicketForm: React.FC<TProps> = ({ form }) => {
  const [selectedQueue, setSelectedQueue] = React.useState<string>('');
  const [selectedIssueType, setSelectedIssueType] = React.useState<string>('');
  const [selectedCompany, setSelectedCompany] = React.useState<string>('');
  const [selectedReporter, setSelectedReporter] = React.useState<string>('');
  const [descriptionText, setDescriptionText] = React.useState<string>('');
  const [generateTitle, { isLoading }] = useGenerateTitleMutation();
  const minDescriptionSymbolCountForGeneration = 30;
  const isAiEnabled = process.env.REACT_APP_AI_IS_ENABLED === 'true';

  const getGenerateTitleButtonText = (): string => {
    if (descriptionText?.length >= minDescriptionSymbolCountForGeneration) {
      return isLoading ? 'Generating...' : 'Generate';
    }
    return `Generate (${
      minDescriptionSymbolCountForGeneration - descriptionText.length
    } symbols remaining)`;
  };

  const handleGenerateTitle = async () => {
    try {
      const generatedTitle = await generateTitle(descriptionText).unwrap();
      form.setFieldsValue({ title: generatedTitle.title });
    } catch (error) {
      toast({
        description: 'Failed to generate title, try again later',
        title: 'Failed to generate title',
        type: 'error',
      });
    }
  };

  const { data: queues } = useGetQueuesQuery({
    sort: 'order,ASC',
    page: 0,
    pageSize: 2000,
  });
  const { data: membersList } = useGetAllAssignedMembersForFilterQuery(
    {
      selectedTicketQueueIds: [selectedQueue],
    },
    {
      skip: !selectedQueue,
    },
  );
  const {
    data: issues,
    isLoading: issuesLoading,
    isFetching: issuesFetching,
  } = useGetIssuesQuery(
    {
      sort: 'name,ASC',
      search: '',
      queueIds: [selectedQueue],
    },
    {
      skip: !selectedQueue,
    },
  );

  const { data: companyList, isLoading: companyLoading } =
    useGetCompaniesCustomersForOptionsQuery({
      sort: 'name,ASC'
    });

  const { data: priorityData } = useGetAllTicketPriorityQuery();

  const { isLoading: contactsListLoading, contactsOptions } =
    useCompanyContactOptionsCompact({
      skip: !selectedCompany,
      companyIds: [selectedCompany],
    });
  const { options: devicesOptions, devicesList } = useDeviceGroupedOptions({
    companyId: selectedCompany,
    contactId: selectedReporter,
  });

  const assigneeOptions = useMemo(() => {
    if (membersList?.length) {
      return membersList?.map(({ id, name, photoUrl }) => ({
        value: id,
        label: <MemberSelectLabel name={name} photoUrl={photoUrl} key={name} />,
      }));
    }
    return [];
  }, [membersList]);

  return (
    <Form
      form={form}
      name="ticket"
      autoComplete="off"
      preserve={false}
      initialValues={{ attachments: [] }}
    >
      <Row gutter={[10, 0]}>
        <Col xs={24}>
          <FormItem
            name="title"
            label="Ticket title"
            rules={[
              {
                required: true,
                message: 'This field is required',
              },
            ]}
          >
            <Input
              placeholder="Type ticket title"
              disabled={isLoading}
              suffix={
                isAiEnabled && (
                  <Button
                    type="primary"
                    size="small"
                    disabled={
                      descriptionText.length <
                        minDescriptionSymbolCountForGeneration || isLoading
                    }
                    loading={isLoading}
                    onClick={handleGenerateTitle}
                    color={TButtonTypes.Info}
                    icon={<Icon icon={TIconNames.AI_BORDER} />}
                  >
                    {getGenerateTitleButtonText()}
                  </Button>
                )
              }
            />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={[10, 0]}>
        <Col xs={24}>
          <FormItem name="description" label="Description (optional)">
            <TextArea
              placeholder="Type here"
              className="customScroll"
              onChange={e => setDescriptionText(e.target.value)}
            />
          </FormItem>
        </Col>
        <Col xs={24}>
          <FormItem name="attachments">
            <FileUploader />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={[10, 0]}>
        <Col xs={24} md={12}>
          <FormItem
            name="companyId"
            label="Company"
            rules={[
              {
                required: true,
                message: 'This field is required',
              },
            ]}
          >
            <Select
              placeholder="Select"
              onChange={(id: string) => {
                setSelectedCompany(id);
                setSelectedReporter('');
                form.resetFields(['reporterId', 'deviceId']);
              }}
              options={companyList?.map(
                ({ id, nameWithAbbreviation }) => ({
                  value: id,
                  label: `${nameWithAbbreviation}`,
                }),
              )}
              disabled={companyLoading}
              showSearch
            />
          </FormItem>
        </Col>
        <Col xs={24} md={12}>
          <FormItem name="reporterId" label="Reporter (optional)">
            <Select
              placeholder="Select"
              options={contactsOptions}
              onChange={(id: string) => {
                setSelectedReporter(id);
                const selectedDeviceId = form.getFieldValue('deviceId');
                const selectedDeviceContact = devicesList?.find(
                  device => device.id === selectedDeviceId,
                )?.contact?.id;
                if (
                  selectedDeviceContact &&
                  selectedDeviceContact === selectedReporter
                ) {
                  // reset device if already selected device has been attached to this contact
                  form.resetFields(['deviceId']);
                }
              }}
              allowClear
              disabled={selectedCompany === '' || contactsListLoading}
              showSearch
            />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={[10, 0]}>
        <Col xs={24} md={12}>
          <FormItem
            name="queueId"
            label="Queue"
            rules={[
              {
                required: true,
                message: 'This field is required',
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Select"
              onChange={(id: string) => {
                setSelectedQueue(id);
                form.resetFields([
                  'issueTypeId',
                  'subIssueTypeId',
                  'assigneeId',
                ]);
              }}
              options={queues?.content?.map(({ id, name }) => ({
                value: id,
                label: `${name}`,
              }))}
            />
          </FormItem>
        </Col>
        <Col xs={24} md={12}>
          <FormItem
            name="assigneeId"
            rules={[
              {
                required: true,
                message: 'This field is required',
              },
            ]}
            label="Primary resource"
          >
            <Select
              showSearch
              placeholder="Select"
              options={assigneeOptions}
              disabled={
                selectedQueue === '' ||
                issuesLoading ||
                issuesFetching ||
                !queues?.content?.find(s => s.id === selectedQueue)?.userGroups
                  ?.length
              }
            />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={[10, 0]}>
        <Col xs={24} md={12}>
          <FormItem name="issueTypeId" label="Issue type (optional)">
            <Select
              showSearch
              placeholder="Select"
              onChange={(id: string) => {
                setSelectedIssueType(id);
                form.resetFields(['subIssueTypeId']);
              }}
              options={issues?.content?.map(({ id, name }) => ({
                value: id,
                label: `${name}`,
              }))}
              disabled={selectedQueue === '' || issuesLoading || issuesFetching}
            />
          </FormItem>
        </Col>
        <Col xs={24} md={12}>
          <FormItem name="issueSubTypeId" label="Issue subtype (optional)">
            <Select
              showSearch
              placeholder="Select"
              options={issues?.content
                ?.find(item => item.id === selectedIssueType)
                ?.subTypes?.map(({ id, name }) => ({
                  value: id,
                  label: `${name}`,
                }))}
              disabled={selectedIssueType === ''}
            />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={[10, 0]}>
        <Col xs={24} md={12}>
          <FormItem
            name="priority"
            label="Priority"
            rules={[
              {
                required: true,
                message: 'This field is required',
              },
            ]}
            initialValue="Low"
          >
            <Select
              placeholder="Select"
              options={priorityData?.map(item => ({
                value: item,
                label: item,
              }))}
            />
          </FormItem>
        </Col>
        <Col xs={24} md={12}>
          <FormItem name="deviceId" label="Device (optional)">
            <Select
              placeholder="Select devices"
              showSearch
              disabled={!selectedCompany}
              options={devicesOptions}
              allowClear
            />
          </FormItem>
        </Col>
      </Row>
    </Form>
  );
};

export { CreateTicketForm };
