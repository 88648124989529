import React, { useMemo } from 'react';
import Form, { FormInstance } from 'antd/es/form';
import { Col, Row } from 'components/Grid/Grid';
import { FormItem } from 'components/Form/FormItem';
import { DatePicker } from 'components/DatePicker/DatePicker';
import { Input, TextArea } from 'components/Input';
import { validationRules } from 'components/Form/validationRules';
import Select from 'components/Select/Select';
import { useGetTaskAssigneesQuery } from 'redux/apiSlices/task.slices';
import { useGetCompaniesCustomersForOptionsQuery } from 'redux/apiSlices/company.slice';
import {
  TBillingMethodEnum,
  TProjectPostDataUi,
} from 'data/types/project.type';
import { MemberSelectLabel } from 'components/MembersSelect';
import { getDateTimeFormats, handleDecimalValues } from 'helpers/utils';
import useCompanyContactOptionsCompact from 'hooks/useCompanyContactOptionsCompact';

type TAddProjectFormProps = {
  form: FormInstance<TProjectPostDataUi>;
  setCustomFieldValue: (fieldName: string, customValue?: any) => void;
};
const AddProjectForm: React.FC<TAddProjectFormProps> = ({
  form,
  setCustomFieldValue,
}) => {
  const [selectedCompany, setSelectedCompany] = React.useState<string>('');

  const { data: assigneesList, isLoading: assigneesListLoading } =
    useGetTaskAssigneesQuery();

  const { data: companyList, isLoading: companyLoading } =
    useGetCompaniesCustomersForOptionsQuery({
      sort: 'name,ASC'
    });

  const { contactsOptions, isLoading: contactsListLoading } =
    useCompanyContactOptionsCompact({
      companyIds: [selectedCompany],
      skip: !selectedCompany,
    });

  const assigneeOptions = useMemo(() => {
    if (assigneesList?.length) {
      return assigneesList?.map(({ id, name, photoUrl }) => ({
        value: id,
        label: <MemberSelectLabel name={name} photoUrl={photoUrl} key={name} />,
      }));
    }
    return [];
  }, [assigneesList]);
  return (
    <Form form={form} name="organizations" autoComplete="off" preserve={false}>
      <Row gutter={[16, 0]}>
        <Col xs={24}>
          <FormItem
            name="title"
            label="Project title"
            rules={[validationRules.required()]}
          >
            <Input placeholder="Project title" />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={[16, 0]}>
        <Col xs={24}>
          <FormItem
            name="description"
            label="Description"
            rules={[validationRules.required()]}
          >
            <TextArea placeholder="Type here" className="customScroll" />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={[16, 0]}>
        <Col xs={24} sm={24} md={24} lg={12}>
          <FormItem
            name="startTime"
            label="Start date (optional)"
            rules={validationRules.startTimeOptional}
          >
            <DatePicker format={getDateTimeFormats().dateFormat} />
          </FormItem>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12}>
          <FormItem
            name="endTime"
            label="End date (optional)"
            rules={validationRules.endTimeOptional}
          >
            <DatePicker format={getDateTimeFormats().dateFormat} />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={[16, 0]}>
        <Col xs={24} md={12}>
          <FormItem
            name="companyId"
            rules={[validationRules.required()]}
            label="Company"
          >
            <Select
              placeholder="Select"
              onChange={(id: string) => {
                setSelectedCompany(id);
                form.resetFields(['reporterId']);
              }}
              options={companyList?.map(
                ({ id, nameWithAbbreviation }) => ({
                  value: id,
                  label: `${nameWithAbbreviation}`,
                }),
              )}
              disabled={companyLoading}
              showSearch
            />
          </FormItem>
        </Col>
        <Col xs={24} md={12}>
          <FormItem name="reporterId" label="Reporter (optional)">
            <Select
              placeholder="Select"
              options={contactsOptions}
              disabled={selectedCompany === '' || contactsListLoading}
              showSearch
            />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={[16, 0]}>
        <Col xs={24} md={12}>
          <FormItem
            name="primaryResourceId"
            rules={[validationRules.required()]}
            label="Primary resource"
          >
            <Select
              placeholder="Select"
              showSearch
              value={form.getFieldValue('assigneeId')}
              options={assigneeOptions}
              disabled={assigneesListLoading}
            />
          </FormItem>
        </Col>
        <Col xs={24} md={12}>
          <FormItem
            name="estimatedHours"
            label="Estimated hours (optional)"
            rules={[validationRules.integerMoreThanZeroOptional()]}
          >
            <Input
              placeholder="Type hours"
              type="number"
              onChange={e =>
                setCustomFieldValue(
                  'estimatedHours',
                  handleDecimalValues(e.target.value, 1),
                )
              }
            />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={[16, 0]} className="mb-6">
        <Col xs={24} md={12}>
          <FormItem
            name="billingMethod"
            rules={[validationRules.required()]}
            label="Billing type"
          >
            <Select
              showSearch
              placeholder="Select"
              options={Object.values(TBillingMethodEnum)
                .map(item => ({
                  value: item,
                  label: item,
                }))
                .sort((a, b) => (a.label > b.label ? 1 : -1))}
            />
          </FormItem>
        </Col>
      </Row>
    </Form>
  );
};

export { AddProjectForm };
