import {
  bulkInvoiceCreateConverter,
  exportInvoiceResponseConverter,
  invoiceConverter,
  invoiceEditConverter,
  mergedInvoiceConverter,
} from 'data/converters/invoice.converter';
import { pagableDataConverter } from 'data/converters/pagableData.converters';
import {
  TBulkInvoicePost,
  TBulkInvoicePostUi,
  TExportInvoiceResponse,
  TExportInvoiceResponseUi,
  TInvoiceGet,
  TInvoiceGetUi,
  TInvoicePost,
  TInvoicePostUi,
  TMergedInvoicePost,
  TMergedInvoicePostUi,
} from 'data/types/invoice.type';
import {
  TPageableDataWithContent,
  TPageableDataWithContentUi,
} from 'data/types/pagableData.type';
import { authSplitApi } from 'redux/helpers/slice.helpers';

export const INVOICE_URL = 'api/company-invoices';

export const invoiceApi = authSplitApi('invoiceApi', [
  'invoices',
]).injectEndpoints({
  endpoints: build => ({
    createBulkInvoice: build.mutation<TBulkInvoicePost, TBulkInvoicePostUi>({
      query(body) {
        return {
          url: `${INVOICE_URL}/bulk`,
          method: 'POST',
          body: bulkInvoiceCreateConverter.toDb(body),
        };
      },
      invalidatesTags: ['invoices'],
    }),
    getAllInvoices: build.query<
      TPageableDataWithContentUi<TInvoiceGetUi[]>,
      {
        page: number;
        pageSize: number;
        filters?: any;
        search?: string;
        sort?: string;
      }
    >({
      query({ page, pageSize, filters, search, sort }) {
        const urlWithParams = INVOICE_URL.concat(
          `?page=${page}&size=${pageSize}`,
        );
        return {
          url: urlWithParams,
          method: 'GET',
          params: {
            ...(sort && { sort }),
            ...(filters && { ...filters }),
            ...(search && { search }),
          },
        };
      },
      providesTags: ['invoices'],
      transformResponse: (data: TPageableDataWithContent<TInvoiceGet[]>) => {
        return {
          ...pagableDataConverter.fromDb(data),
          content: data?.content?.length
            ? data.content.map(invoice => invoiceConverter.fromDb(invoice))
            : [],
        };
      },
    }),
    getInvoiceById: build.query<TInvoiceGetUi, string>({
      query(invoiceId) {
        return {
          url: `${INVOICE_URL}/${invoiceId}`,
          method: 'GET',
        };
      },
      transformResponse: (data: TInvoiceGet) => invoiceConverter.fromDb(data),
      providesTags: ['invoice'],
    }),
    createInvoiceFree: build.mutation<TInvoicePost, TInvoicePostUi>({
      query(body) {
        return {
          url: `${INVOICE_URL}/free`,
          method: 'POST',
          body: invoiceConverter.toDb(body),
        };
      },
      invalidatesTags: ['invoices'],
    }),
    createInvoiceTicketMaterial: build.mutation<
      TInvoicePost,
      TInvoicePostUi & { ticketId?: string }
    >({
      query({ ticketId, ...body }) {
        return {
          url: `${INVOICE_URL}/ticket-materials/${ticketId}`,
          method: 'POST',
          body: invoiceConverter.toDb(body),
        };
      },
      invalidatesTags: ['invoices'],
    }),
    createInvoiceProject: build.mutation<
      TInvoicePost,
      TInvoicePostUi & { projectId: string }
    >({
      query({ projectId, ...body }) {
        return {
          url: `${INVOICE_URL}/project/${projectId}`,
          method: 'POST',
          body: invoiceConverter.toDb(body),
        };
      },
      invalidatesTags: ['invoices'],
    }),
    invoiceExport: build.mutation<
      TExportInvoiceResponseUi,
      TInvoicePostUi & { invoiceId: string }
    >({
      query({ invoiceId, ...body }) {
        return {
          url: `${INVOICE_URL}/${invoiceId}/export-as`,
          method: 'POST',
          body: invoiceEditConverter.toDb(body),
        };
      },
      transformResponse: (data: TExportInvoiceResponse) =>
        exportInvoiceResponseConverter.fromDb(data),
      invalidatesTags: ['invoices'],
    }),
    editInvoice: build.mutation<
      TInvoicePost,
      TInvoicePostUi & { invoiceId: string }
    >({
      query({ invoiceId, ...body }) {
        return {
          url: `${INVOICE_URL}/${invoiceId}`,
          method: 'PUT',
          body: invoiceEditConverter.toDb(body),
        };
      },
      invalidatesTags: ['invoices'],
    }),
    deleteInvoiceById: build.mutation<void, { id: string }>({
      query({ id }) {
        return {
          url: `${INVOICE_URL}/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['invoices'],
    }),
    createMergedInvoice: build.mutation<
      TMergedInvoicePost,
      TMergedInvoicePostUi
    >({
      query(body) {
        return {
          url: `${INVOICE_URL}/merge`,
          method: 'POST',
          body: mergedInvoiceConverter.toDb(body),
        };
      },
      invalidatesTags: ['invoices'],
    }),
    invoiceExportAndSend: build.mutation<
      TExportInvoiceResponseUi,
      TInvoicePostUi & { invoiceId: string }
    >({
      query({ invoiceId, ...body }) {
        return {
          url: `${INVOICE_URL}/${invoiceId}/export-send-as`,
          method: 'POST',
          body: invoiceEditConverter.toDb(body),
        };
      },
      transformResponse: (data: TExportInvoiceResponse) =>
        exportInvoiceResponseConverter.fromDb(data),
      invalidatesTags: ['invoices'],
    }),
    invoiceExportCSV: build.mutation<
      Blob,
      {
        ids: React.Key[];
        filters?: any;
        search?: string;
      }
    >({
      query({ ids, filters, search }) {
        return {
          url: INVOICE_URL.concat(`/export-csv`),
          method: 'POST',
          params: {
            ...(filters && { ...filters }),
            ...(search && { search }),
          },
          responseHandler: response => response.text(),
          ...(ids.length && { body: ids }),
        };
      },
      invalidatesTags: ['invoices'],
    }),
  }),
  overrideExisting: false,
});

export const {
  useCreateBulkInvoiceMutation,
  useGetAllInvoicesQuery,
  useGetInvoiceByIdQuery,
  useCreateInvoiceFreeMutation,
  useCreateInvoiceTicketMaterialMutation,
  useCreateInvoiceProjectMutation,
  useInvoiceExportMutation,
  useEditInvoiceMutation,
  useDeleteInvoiceByIdMutation,
  useCreateMergedInvoiceMutation,
  useInvoiceExportAndSendMutation,
  useInvoiceExportCSVMutation
} = invoiceApi;
