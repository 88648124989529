import React, { useMemo } from 'react';
import { TOptionType } from 'data/types/generalDataTypes';
import { useGetDevicesCompactQuery } from 'redux/apiSlices/devices.slice';
import LabelWithTag from 'components/LabelSelectOptions/LabelWithTag';

type TUseDeviceGroupedOptionsProps = {
  companyId?: string;
  contactId?: string;
};

export const useDeviceGroupedOptions = ({
  companyId,
  contactId,
}: TUseDeviceGroupedOptionsProps) => {
  const { data: devicesList, isLoading } = useGetDevicesCompactQuery(
    {
      filters: {
        companyIds: companyId ? [companyId] : [],
      },
    },
    {
      skip: !companyId,
    },
  );
  const devicesOptions = useMemo(() => {
    const reporterAttachedOptions: TOptionType[] = [];
    const companyAttachedOptions: TOptionType[] = [];
    (devicesList || [])?.forEach(
      ({ id, name, contact, isManaged, isActive }) => {
        if (!!contactId && contact?.id === contactId) {
          reporterAttachedOptions.push({
            value: id,
            label: (
              <LabelWithTag
                title={name}
                notManaged={!isManaged}
                notActive={!isActive}
                key={name}
              />
            ),
          });
        } else {
          companyAttachedOptions.push({
            value: id,
            label: (
              <LabelWithTag
                title={name}
                notManaged={!isManaged}
                notActive={!isActive}
                key={name}
              />
            ),
          });
        }
      },
    );
    const groupedOptions = [];
    if (reporterAttachedOptions.length) {
      groupedOptions.unshift({
        label: 'Reporter attached',
        options: reporterAttachedOptions,
      });
    }
    if (companyAttachedOptions.length) {
      groupedOptions.push({
        label: 'Company attached',
        options: companyAttachedOptions,
      });
    }
    return groupedOptions;
  }, [devicesList, contactId]);

  return { options: devicesOptions, isLoading, devicesList };
};
