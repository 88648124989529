import React, { FC, useEffect, useMemo, useState } from 'react';
import Modal from 'components/Modal/Modal';

import { ModalWidthEnum } from 'components/Modal/Modal.types';
import Form from 'components/Form/Form';
import { Col, Row } from 'components/Grid/Grid';
import { FormItem } from 'components/Form/FormItem';
import Select from 'components/Select/Select';
import useForm from 'hooks/useForm';
import { Input } from 'components/Input';
import Checkbox from 'components/Checkbox';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useGetContactsForOptionsQuery } from 'redux/apiSlices/contact.slice';
import { useGetCompaniesCustomersForOptionsQuery } from 'redux/apiSlices/company.slice';
import { useCreateDeviceMutation } from 'redux/apiSlices/devices.slice';
import { TDevicePostUi } from 'data/types/devices.type';
import { toast } from 'components/Notification';
import { validationRules } from 'components/Form/validationRules';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { changeDeviceAddModal } from 'redux/slices';
import { TRtkErrorType } from 'data/types/generalDataTypes';

const AddDeviceModal: FC = () => {
  const { form } = useForm();
  const dispatch = useAppDispatch();
  const { addDeviceModal } = useAppSelector(state => ({
    addDeviceModal: state.appSettings.addDeviceModal,
  }));
  const [selectedCompany, setSelectedCompany] = useState('');
  const [isManageDisabled, setIsManageDisabled] = useState(false);
  const [createDevice, { isLoading }] = useCreateDeviceMutation();
  const { data: companiesData } = useGetCompaniesCustomersForOptionsQuery(
    {
    },
    { skip: addDeviceModal.companyDefaultId !== undefined && addDeviceModal.companyDefaultId.length > 0},
  );
  const { data: contactsData } = useGetContactsForOptionsQuery(
    {
      filters: {
        companies: [selectedCompany],
      },
    },
    { skip: selectedCompany === undefined || selectedCompany.length === 0 },
  );
  useEffect(() => {
    if (addDeviceModal.companyDefaultId) {
      setSelectedCompany(addDeviceModal.companyDefaultId);
    }
  }, [addDeviceModal]);
  const companiesOptions = useMemo(() => {
    if (companiesData) {
      return companiesData.map(company => {
        return {
          label: company.name,
          value: company.id,
        };
      });
    }
    return [];
  }, [companiesData]);
  const contactsOptions = useMemo(() => {
    if (contactsData) {
      return contactsData.map(contact => {
        return {
          label: contact.name,
          value: contact.id,
        };
      });
    }
    return [];
  }, [contactsData]);
  const handleCloseNewDevice = () => {
    form.resetFields();
    dispatch(
      changeDeviceAddModal({
        visibleModal: false,
        companyDefaultId: '',
      }),
    );
  };

  const handelAddDevice = async () => {
    const formValues = form.getFieldsValue() as TDevicePostUi;
    if (addDeviceModal.companyDefaultId) {
      formValues.companyId = addDeviceModal.companyDefaultId;
    }
    try {
      await form.validateFields();
      await createDevice(formValues).unwrap();
      handleCloseNewDevice();
      toast({
        title: 'Create Device',
        description: 'Device created successfully',
        type: 'success',
      });
    } catch (error) {
      toast({
        description: (error as TRtkErrorType)?.data.message,
        title: 'Create Device',
        type: 'error',
      });
    }
  };
  const handleChaneIsActive = (e: CheckboxChangeEvent) => {
    if (!e.target.checked) {
      form.setFieldsValue({
        managed: false,
      });
      setIsManageDisabled(true);
    } else setIsManageDisabled(false);
  };

  return (
    <Modal
      size={ModalWidthEnum.Medium}
      okText="Add device"
      visible={addDeviceModal.visibleModal}
      title="Create new device"
      onOk={handelAddDevice}
      confirmLoading={isLoading}
      onCancel={handleCloseNewDevice}
    >
      <Form form={form}>
        <Row gutter={[10, 0]} align="bottom">
          {!addDeviceModal.companyDefaultId && (
            <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
              <FormItem
                name="companyId"
                label="Company"
                rules={[validationRules.required()]}
              >
                <Select
                  showSearch
                  placeholder="Select Company"
                  options={companiesOptions}
                  onSelect={setSelectedCompany}
                  onChange={() => {
                    form.resetFields(['contactId']);
                  }}
                />
              </FormItem>
            </Col>
          )}
          <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
            <FormItem name="contactId" label="Contact">
              <Select
                showSearch
                placeholder="Select Contact"
                disabled={!selectedCompany}
                options={contactsOptions}
              />
            </FormItem>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
            <FormItem
              name="name"
              label="Name"
              rules={[validationRules.required()]}
            >
              <Input placeholder="Type Device Name" />
            </FormItem>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
            <FormItem name="model" label="Model">
              <Input placeholder="Type Device Model" />
            </FormItem>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
            <FormItem name="deviceType" label="Device Type">
              <Input placeholder="Type Device type" />
            </FormItem>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={6} xxl={6}>
            <FormItem name="active" initialValue valuePropName="checked">
              <Checkbox
                defaultChecked
                label="Active"
                onChange={handleChaneIsActive}
              />
            </FormItem>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={6} xxl={6}>
            <FormItem name="managed" initialValue valuePropName="checked">
              <Checkbox
                defaultChecked
                label="Managed"
                disabled={isManageDisabled}
              />
            </FormItem>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
export default AddDeviceModal;
