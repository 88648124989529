import { TOrganizationDataUi, TOrganizationData, TConverter } from 'data/types';
import { TCreateConverter, TFetchConverter } from 'data/types/converter.types';
import {
  TWorkingHourDataUI,
  TWorkingHourData,
  TPortalSettings,
  TPortalSettingsUi,
  TEditPortalSubdomainUi,
  TEditPortalSubdomain,
  TDomainSuffixUi,
  TDomainSuffix,
  TEditOrganizationLogoUi,
  TEditOrganizationLogo,
  TEditOrganizationName,
  TEditOrganizationNameUi,
  TEditOrganizationWebsiteUi,
  TEditOrganizationWebsite,
  TNonWorkingDaysUi,
  TNonWorkingDays,
  ECountry,
  ECountryUI,
  TNotificationsNewTicket,
  TNotificationsNewTicketUi,
  TNotificationsNewCommentUi,
  TNotificationsNewComment,
} from 'data/types/organization.types';
import moment from 'moment';
import { GetFirstLetters } from './general.converters';

export const workingHourConverter: TConverter<
  TWorkingHourDataUI,
  TWorkingHourData
> = {
  toDb: data => {
    return data.map(workingHour => ({
      dayOfWeek: workingHour.dayOfWeek,
      start: workingHour.start,
      end: workingHour.end,
    }));
  },
  fromDb: data => {
    return data.map(workingHour => ({
      dayOfWeek: workingHour.dayOfWeek,
      start: workingHour.start,
      end: workingHour.end,
    }));
  },
};

export const nonWorkingDaysConverter: TConverter<
  TNonWorkingDaysUi,
  TNonWorkingDays
> = {
  toDb: data => ({
    id: data.id,
    date: data.day && moment(data.day).format('YYYY-MM-DD'),
    repeatAnnually: data.repeatable,
  }),
  fromDb: data => ({
    id: data.id,
    day: data.date && moment(data.date).format('YYYY MMM DD'),
    repeatable: data.repeatAnnually,
  }),
};

export const organizationCountryConverter: TConverter<ECountryUI, ECountry> = {
  fromDb: data => {
    const convertedDataMapper: {
      [key in ECountry]: ECountryUI;
    } = {
      [ECountry.UK]: ECountryUI.UK,
      [ECountry.USA]: ECountryUI.USA,
      [ECountry.CANADA]: ECountryUI.CANADA,
      [ECountry.AUSTRALIA]: ECountryUI.AUSTRALIA,
      [ECountry.NEW_ZEALAND]: ECountryUI.NEW_ZEALAND,
    };
    return convertedDataMapper[data];
  },
  toDb: data => {
    const convertedDataMapper: {
      [key in ECountryUI]: ECountry;
    } = {
      [ECountryUI.USA]: ECountry.USA,
      [ECountryUI.UK]: ECountry.UK,
      [ECountryUI.CANADA]: ECountry.CANADA,
      [ECountryUI.AUSTRALIA]: ECountry.AUSTRALIA,
      [ECountryUI.NEW_ZEALAND]: ECountry.NEW_ZEALAND,
    };
    return convertedDataMapper[data];
  },
};

export const notificationsNewTicketConverter: TConverter<
  TNotificationsNewTicketUi,
  TNotificationsNewTicket
> = {
  toDb: (data: TNotificationsNewTicketUi): TNotificationsNewTicket => {
   const convertedObj: TNotificationsNewTicket = {
     id: data.id,
     type: data.type,
     source: data.source,
     reporterIncluded: data.reporterIncluded,
     enabled: data.enabled,
     notificationList: data.notificationList,
   }
   return convertedObj;
  },
  fromDb: (data: TNotificationsNewTicket): TNotificationsNewTicketUi => {
    const convertedObj: TNotificationsNewTicketUi = {
      id: data.id,
      type: data.type,
      source: data.source,
      reporterIncluded: data.reporterIncluded,
      enabled: data.enabled,
      notificationList: data.notificationList,
    }
    return convertedObj;
  }
}

export const notificationsNewCommentConverter: TConverter<
  TNotificationsNewCommentUi,
  TNotificationsNewComment
> = {
  toDb: (data: TNotificationsNewCommentUi): TNotificationsNewComment => {
   const convertedObj: TNotificationsNewComment = {
     id: data.id,
     type: data.type,
     source: data.source,
     reporterIncluded: data.reporterIncluded,
     enabled: data.enabled,
     notificationList: data.notificationList,
   }
   return convertedObj;
  },
  fromDb: (data: TNotificationsNewComment): TNotificationsNewCommentUi => {
    const convertedObj: TNotificationsNewCommentUi = {
      id: data.id,
      type: data.type,
      source: data.source,
      reporterIncluded: data.reporterIncluded,
      enabled: data.enabled,
      notificationList: data.notificationList,
    }
    return convertedObj;
  }
}

const organizationConverter: TConverter<
  TOrganizationDataUi,
  TOrganizationData
> = {
  toDb: (data: TOrganizationDataUi): TOrganizationData => {
    const convertedObj: TOrganizationData = {
      name: data.name,
      country: organizationCountryConverter.toDb(data.country),
      timeZone: data.timeZone,
      size: data.size,
      workingHours: data?.workingHours?.length
        ? workingHourConverter.toDb(data.workingHours)
        : [],
      address: data.address,
    };
    if (data.logoUrl) convertedObj.logoUrl = data.logoUrl;
    if (data.website) convertedObj.website = data.website;
    return convertedObj;
  },
  fromDb: (data: TOrganizationData): TOrganizationDataUi => {
    const convertedObj: TOrganizationDataUi = {
      id: data.id,
      onboardState: data.onboardState,
      name: data.name,
      country: organizationCountryConverter.fromDb(data.country),
      timeZone: data.timeZone,
      size: data.size,
      logoUrl: data.logoUrl,
      website: data.website,
      workingHours: data.workingHours.length
        ? workingHourConverter.fromDb(data.workingHours)
        : [],
      address: data.address,
      firstLetters: GetFirstLetters.fromDb(data.name),
      nonWorkingDays: (data.nonWorkingDays || []).map(el =>
        nonWorkingDaysConverter.fromDb(el),
      ),
    };
    return convertedObj;
  },
};

export const portalSettingsConverter: TFetchConverter<
  TPortalSettingsUi,
  TPortalSettings
> = {
  fromDb: (data: TPortalSettings) => {
    return {
      domain: data.domain,
      isVerified: data.isVerified,
      isMisconfigured: data.isMisconfigured,
      isDomainNeedsVerified: data.isDomainNeedsVerified,
      dnsRecords: data.dnsRecords
    };
  },
};

export const portalSubdomainEditConverter: TCreateConverter<
  TEditPortalSubdomainUi,
  TEditPortalSubdomain
> = {
  toDb: data => ({
    value: data.value || null,
  }),
};

export const domainSuffixConverter: TFetchConverter<
  TDomainSuffixUi,
  TDomainSuffix
> = {
  fromDb: (data: TDomainSuffix) => {
    return {
      value: data.value,
    };
  },
};

export const organizationLogoEditConverter: TCreateConverter<
  TEditOrganizationLogoUi,
  TEditOrganizationLogo
> = {
  toDb: data => ({
    value: data.value,
  }),
};
export const organizationNameEditConverter: TCreateConverter<
  TEditOrganizationNameUi,
  TEditOrganizationName
> = {
  toDb: data => ({
    value: data.value,
  }),
};
export const organizationWebsiteEditConverter: TCreateConverter<
  TEditOrganizationWebsiteUi,
  TEditOrganizationWebsite
> = {
  toDb: data => ({
    value: data.value,
  }),
};

export default organizationConverter;
