import moment from 'moment/moment';
import { TCreateConverter, TFetchConverter } from '../types/converter.types';
import {
  TattachDevicePost,
  TattachDevicePostUi,
  TbulkAttachDevicesPost,
  TbulkAttachDevicesPostUI,
  TdeviceActivePost,
  TdeviceActivePostUi,
  TdeviceManagedPost,
  TdeviceManagedPostUi,
  TDevicePost,
  TDevicePostUi,
  TDevicesGet,
  TDevicesGetUi,
  TUpdateDeviceType,
  TUpdateDeviceTypeUi,
  TUpdateDeviceName,
  TUpdateDeviceNameUi,
  TUpdateDeviceModelUi,
  TUpdateDeviceModel,
  TDevicesCompactGetUi,
  TDevicesCompactGet,
} from '../types/devices.type';
import {
  companyShortDataConverter,
  contactShortDataConverter,
} from './shortDataConverter';
import {
  DeviceActiveStatuses,
  DeviceFilters,
  DeviceManagedStatuses,
  EDevicesSourcesUi,
} from '../types/devicesEnum.type';
import {
  deviceSourceAsParamConverter,
  deviceSourceConverter,
} from './devicesEnum.converter';
import { getDateTimeFormats } from '../../helpers/utils';

export const bulkAttachDevicesConverter: TCreateConverter<
  TbulkAttachDevicesPostUI,
  TbulkAttachDevicesPost
> = {
  toDb: data => {
    return {
      companyId: data.companyId,
      contactDevices: Object.keys(data.contactDevices).map(key => {
        return {
          contactId: key,
          devices: data.contactDevices[key],
        };
      }),
    };
  },
};
export const attachDeviceConverter: TCreateConverter<
  TattachDevicePostUi,
  TattachDevicePost
> = {
  toDb: data => {
    return {
      value: data.contactId,
    };
  },
};

export const deviceManagedConverter: TCreateConverter<
  TdeviceManagedPostUi,
  TdeviceManagedPost
> = {
  toDb: data => {
    return {
      value: data.managed,
    };
  },
};
export const deviceActiveConverter: TCreateConverter<
  TdeviceActivePostUi,
  TdeviceActivePost
> = {
  toDb: data => {
    return {
      value: data.active,
    };
  },
};

export const devicesConverter: TFetchConverter<TDevicesGetUi, TDevicesGet> = {
  fromDb: data => {
    const { dateTimeFormat, dateFormat } = getDateTimeFormats();

    return {
      id: data.id,
      deviceType: data.deviceType,
      company: companyShortDataConverter.fromDb(data.company),
      contact: data.contact && contactShortDataConverter.fromDb(data.contact),
      isActive: data.isActive,
      isDeleted: data.isDeleted,
      model: data.model,
      isManaged: data.isManaged,
      source: data.source && deviceSourceConverter.fromDb(data.source),
      name: data.name,
      creationDateTime:
        data.creationDateTime &&
        moment(data.creationDateTime).format(dateTimeFormat),
      creationDate:
        data.creationDateTime &&
        moment(data.creationDateTime).format(dateFormat),
    };
  },
};

export const devicesCompactConverter: TFetchConverter<TDevicesCompactGetUi, TDevicesCompactGet> = {
  fromDb: data => {

    return {
      id: data.id,
      contact: data.contact,
      isActive: data.isActive,
      isManaged: data.isManaged,
      name: data.name,
    };
  },
};

export const deviceCreateConverter: TCreateConverter<
  TDevicePostUi,
  TDevicePost
> = {
  toDb: data => {
    return {
      active: data.active,
      deviceType: data.deviceType,
      companyId: data.companyId,
      contactId: data.contactId,
      name: data.name,
      managed: data.managed,
      model: data.model,
    };
  },
};
export const deviceFilterConverter = (filters: {
  [key in DeviceFilters]?: string[];
}) => {
  const deviceFilterToDB = {
    ...(filters?.companyIds?.length && {
      [DeviceFilters.COMPANY]: filters?.companyIds,
    }),
    ...(filters?.contactIds?.length && {
      [DeviceFilters.CONTACT]: filters?.contactIds,
    }),
    ...(filters?.isActive?.length && {
      [DeviceFilters.ACTIVE]:
        filters?.isActive[0] === DeviceActiveStatuses.ACTIVE,
    }),
    ...(filters?.isManaged?.length && {
      [DeviceFilters.MANAGE]:
        filters?.isManaged[0] === DeviceManagedStatuses.MANAGED,
    }),
    ...(filters?.sources?.length && {
      [DeviceFilters.SOURCE]: filters?.sources.map(source =>
        deviceSourceAsParamConverter.toDb(source as EDevicesSourcesUi),
      ),
    }),
  };
  return deviceFilterToDB;
};

export const deviceUpdateNameConverter: TCreateConverter<
  TUpdateDeviceNameUi,
  TUpdateDeviceName
> = {
  toDb: data => {
    return {
      value: data.value,
    };
  },
};

export const deviceTypeConverter: TCreateConverter<
  TUpdateDeviceTypeUi,
  TUpdateDeviceType
> = {
  toDb: data => {
    return {
      value: data.value,
    };
  },
};

export const deviceModelConverter: TCreateConverter<
  TUpdateDeviceModelUi,
  TUpdateDeviceModel
> = {
  toDb: data => {
    return {
      value: data.value,
    };
  },
};
