import { organizationConverter } from 'data/converters';
import {
  domainSuffixConverter,
  nonWorkingDaysConverter,
  notificationsNewCommentConverter,
  notificationsNewTicketConverter,
  organizationLogoEditConverter,
  organizationNameEditConverter,
  organizationWebsiteEditConverter,
  portalSettingsConverter,
  portalSubdomainEditConverter,
} from 'data/converters/organization.converters';
import { TOrganizationData, TOrganizationDataUi } from 'data/types';
import {
  TDomainSuffix,
  TDomainSuffixUi,
  TEditOrganizationLogoUi,
  TEditOrganizationNameUi,
  TEditOrganizationWebsiteUi,
  TEditPortalSubdomain,
  TEditPortalSubdomainUi,
  TNonWorkingDaysUi,
  TNotificationsNewComment,
  TNotificationsNewCommentUi,
  TNotificationsNewTicket,
  TNotificationsNewTicketUi,
  TPortalSettings,
  TPortalSettingsUi,
} from 'data/types/organization.types';
import { authSplitApi } from 'redux/helpers/slice.helpers';

export const ORGANIZATION_URL = 'api/organizations';
const PORTAL_SETTINGS = 'api/organizations/client-portal-settings';

export const organizationsApi = authSplitApi('organizations', [
  'organization',
  'portalSuffix',
  'portalSettings',
  'notificationsNewTicket',
  'notificationsNewComment'
]).injectEndpoints({
  endpoints: build => ({
    postOrganizations: build.mutation<TOrganizationData, TOrganizationDataUi>({
      query(body) {
        return {
          url: ORGANIZATION_URL,
          method: 'POST',
          body: organizationConverter.toDb(body),
        };
      },
    }),
    putOrganizations: build.mutation<TOrganizationData, TOrganizationDataUi>({
      query(body) {
        return {
          url: `${ORGANIZATION_URL}/${body.id}`,
          method: 'PUT',
          body: organizationConverter.toDb(body),
        };
      },
      invalidatesTags: ['organization'],
    }),
    getOrganizationById: build.query<TOrganizationDataUi, string | undefined>({
      query(id) {
        return {
          url: `${ORGANIZATION_URL}/${id}`,
          method: 'GET',
        };
      },
      providesTags: ['organization'],
      transformResponse: (data: TOrganizationData) =>
        organizationConverter.fromDb(data),
    }),
    getNotificationsNewTicket: build.query<TNotificationsNewTicketUi[], void>({
      query() {
        return {
          url: `${ORGANIZATION_URL}/notifications/new-ticket`,
          method: 'GET',
        };
      },
      providesTags: ['notificationsNewTicket'],
      transformResponse: (data: TNotificationsNewTicket[]) => data.map(x => notificationsNewTicketConverter.fromDb(x))
    }),
    getNotificationsNewComment: build.query<TNotificationsNewCommentUi[], void>({
      query() {
        return {
          url: `${ORGANIZATION_URL}/notifications/new-comment`,
          method: 'GET',
        };
      },
      providesTags: ['notificationsNewComment'],
      transformResponse: (data: TNotificationsNewComment[]) => data.map(x => notificationsNewCommentConverter.fromDb(x))
    }),
    searchOnTable: build.query<
      string[],
      {
        tableName: string;
        filedName: string;
        value: string;
      }
    >({
      query({ tableName, filedName, value }) {
        // TODO: create a converter for this that make a url from the query params
        const urlWithParams = ORGANIZATION_URL?.concat(
          `/search?tableName=${tableName}&filedName=${filedName}&value=${value}`,
        );

        return {
          url: urlWithParams,
          method: 'GET',
        };
      },
    }),
    getDomainSuffix: build.query<TDomainSuffixUi, void>({
      query() {
        return {
          url: `${PORTAL_SETTINGS}/domain-suffix`,
          method: 'GET',
        };
      },
      providesTags: ['portalSuffix'],
      transformResponse: (data: TDomainSuffix) =>
        domainSuffixConverter.fromDb(data),
    }),
    getPortalSettings: build.query<TPortalSettingsUi, void>({
      query() {
        return {
          url: `${PORTAL_SETTINGS}`,
          method: 'GET',
        };
      },
      providesTags: ['portalSettings'],
      transformResponse: (data: TPortalSettings) =>
        portalSettingsConverter.fromDb(data),
    }),
    editPortalSettings: build.mutation<
      TEditPortalSubdomainUi,
      TEditPortalSubdomain
    >({
      query(body) {
        return {
          url: `${PORTAL_SETTINGS}/domain`,
          method: 'PATCH',
          body: portalSubdomainEditConverter.toDb(body),
        };
      },
      invalidatesTags: ['portalSettings'],
    }),
    editOrganizationLogo: build.mutation<
      TOrganizationData,
      TEditOrganizationLogoUi
    >({
      query(body) {
        return {
          url: `${ORGANIZATION_URL}/logo`,
          method: 'PATCH',
          body: organizationLogoEditConverter.toDb(body),
        };
      },
      invalidatesTags: ['organization'],
    }),
    editOrganizationName: build.mutation<
      TOrganizationData,
      TEditOrganizationNameUi
    >({
      query(body) {
        return {
          url: `${ORGANIZATION_URL}/name`,
          method: 'PATCH',
          body: organizationNameEditConverter.toDb(body),
        };
      },
      invalidatesTags: ['organization'],
    }),
    editOrganizationWebsite: build.mutation<
      TOrganizationData,
      TEditOrganizationWebsiteUi
    >({
      query(body) {
        return {
          url: `${ORGANIZATION_URL}/website`,
          method: 'PATCH',
          body: organizationWebsiteEditConverter.toDb(body),
        };
      },
      invalidatesTags: ['organization'],
    }),
    setupNonWorkingDays: build.mutation<TOrganizationData, TNonWorkingDaysUi[]>(
      {
        query(body) {
          return {
            url: `${ORGANIZATION_URL}/non-working-days`,
            method: 'PATCH',
            body: body.map(el => nonWorkingDaysConverter.toDb(el)),
          };
        },
        invalidatesTags: ['organization'],
      },
    ),
    patchTicketReporterOnNotification: build.mutation<void, {enabled: boolean, id: string}>(
      {
        query({enabled, id}) {
          return {
            url: `${ORGANIZATION_URL}/notifications/ticket-reporter`,
            method: 'PATCH',
            body: {enabled, id}
          }
        },
        invalidatesTags: ['notificationsNewTicket', 'notificationsNewComment']
      }
    ),
    patchEnableOnNotification: build.mutation<void, {enabled: boolean, id: string}>(
      {
        query({enabled, id}) {
          return {
            url: `${ORGANIZATION_URL}/notifications/enable`,
            method: 'PATCH',
            body: {enabled, id}
          }
        },
        invalidatesTags: ['notificationsNewTicket', 'notificationsNewComment']
      }
    ),
    patchNotificationListValue: build.mutation<void, {value: string, id: string, enabled: boolean}>(
      {
        query({value, id, enabled}) {
          return {
            url: `${ORGANIZATION_URL}/notifications/notification-list`,
            method: 'PATCH',
            body: {value, id, enabled}
          }
        },
        invalidatesTags: ['notificationsNewTicket', 'notificationsNewComment']
      }
    ),
}),
  overrideExisting: false,
});

export const {
  usePostOrganizationsMutation,
  usePutOrganizationsMutation,
  useGetOrganizationByIdQuery,
  useGetNotificationsNewTicketQuery,
  useGetNotificationsNewCommentQuery,
  useSearchOnTableQuery,
  useGetDomainSuffixQuery,
  useGetPortalSettingsQuery,
  useEditPortalSettingsMutation,
  useEditOrganizationLogoMutation,
  useEditOrganizationNameMutation,
  useEditOrganizationWebsiteMutation,
  useSetupNonWorkingDaysMutation,
  usePatchTicketReporterOnNotificationMutation,
  usePatchEnableOnNotificationMutation,
  usePatchNotificationListValueMutation
} = organizationsApi;
